<template>
  <Layout>
    <div
      class="h-[calc(100vh-40px)] md:h-[calc(100vh-80px)] xl:h-[calc(100vh-100px)]"
    >
      <slot />
    </div>
  </Layout>
</template>

<script setup lang="ts">
import Layout from '~/components/TheLayout.vue'
/** stores */
import { useMenuStore } from '~/stores/menu'

/** app */
const { setActiveMenu } = useMenuStore()

/** lifecycle */
onMounted(() => {
  setActiveMenu({
    activeMenu: null,
    activeSubMenu: null,
  })
})
</script>
